import React from "react";
import "./style.css"; // Import the CSS file

const BlockQuote = () => {
  return (
    <>
      <blockquote className="blockquote">
        <p>
          At Dave's Laundry Services, we are dedicated to delivering top-notch
          laundry solutions with unmatched quality and care.
        </p>
      </blockquote>
      <br />
      <blockquote className="otro-blockquote">
        Our mission is to ensure your garments receive the utmost attention and
        freshness while providing convenience and reliability for all your
        laundry needs.
        <span>David Storch</span>
      </blockquote>
    </>
  );
};

export default BlockQuote;
