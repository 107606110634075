// src/components/Header.js
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../Pages/Home/home.css"; // Import the CSS file

const Header = () => {
  return (
    <Navbar className="navbar-wrapper" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <h1 className="pb-0 mb-0 header-text font-dls">Dave's</h1>
          <h6 className="header-text font-dls"> Laundry Service</h6>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="justify-content-end header-text font-dls"
            style={{ width: "100%" }}
          >
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about-us">About</Nav.Link>
            <Nav.Link href="/services">Services</Nav.Link>
            <Nav.Link href="/pricing">Pricing</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
