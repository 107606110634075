// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./Pages/Home/Home";
import Services from "./Pages/Services";
import Pricing from "./Pages/Pricing/Pricing";
import TermsAndConditions from "./Pages/Terms And Condition";
import StickyCallButton from "./components/stickyCall/StickyCallButton";
import AboutUs from "./Pages/About/index";

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/terms" element={<TermsAndConditions />} />
      </Routes>
      <Footer />
      <StickyCallButton />
    </Router>
  );
};

export default App;
