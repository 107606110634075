import React, { useState } from "react";
import { Card, Col, Row, Button, Modal, Container } from "react-bootstrap";
import {
  FaHome,
  FaBusinessTime,
  FaHospital,
  FaRunning,
  FaTruckPickup,
  FaCalendarAlt,
} from "react-icons/fa";
import "./style.css";
import CarouselComponent from "../../components/ImagesSlider";

const services = [
  {
    icon: <FaHome />,
    title: "Home Laundry Service",
    description:
      "Professional and convenient laundry services for residential customers.",
  },
  {
    icon: <FaBusinessTime />,
    title: "Commercial Laundry Service",
    description: "Tailored laundry solutions for businesses of small sizes.",
    details: `Dave’s Laundry Services offers efficient and cost-effective solutions for businesses, including doctors rooms, physio rooms, and gyms. We handle all fabrics and stains with flexible scheduling options.
    Using top-quality equipment and detergents, we ensure your linens, uniforms, and towels are expertly cleaned and sanitised.
    Contact us today to streamline your business’s laundry needs with our professional and affordable service.`,
  },
  {
    icon: <FaHospital />,
    title: "Medical Centre Laundry Service",
    description:
      "Specialized laundry services for medical centres and clinics.",
  },

  {
    icon: <FaRunning />,
    title: "Sports Center Laundry Services",
    description: "Professional cleaning for sports teams' uniforms and gear.",
  },
  {
    icon: <FaTruckPickup />,
    title: "Pickup Laundry Service",
    description:
      "Hassle-free pickup and delivery services for your laundry needs.",
  },

  {
    icon: <FaCalendarAlt />,
    title: "Laundry Service Recurring Bookings",
    description:
      "Convenient pickup and delivery services with recurring booking options.",
  },
];

const ServiceCardView = () => {
  const [show, setShow] = useState(false);
  const [service, setService] = useState({});

  const handleClose = () => setShow(false);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{service.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <Card.Text>{service.details}</Card.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container ">
        <CarouselComponent />
      </div>
      <Container className="full-height">
        <Row xs={1} sm={2} md={3} lg={4} className="g-4 p-5">
          {services.map((service, index) => (
            <Col key={index}>
              <Card className="h-100 text-center hvr-float-shadow">
                <Card.Body>
                  <div style={{ fontSize: "40px", color: "#007bff" }}>
                    {service.icon}
                  </div>
                  <Card.Title className="mt-3">{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                  {service.details && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        setService(service);
                        setShow(true);
                      }}
                    >
                      Learn More
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default ServiceCardView;
