import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import "./style.css"; // Ensure you have this CSS file for custom styles
import { FaEnvelope, FaFacebook, FaInstagram } from "react-icons/fa";
import { FaMobileRetro } from "react-icons/fa6";

const AboutUs = () => {
  return (
    <Container fluid className="about-us-container py-5 ">
      <Row className="align-items-center">
        <Col md={6} className="text-center text-md-start">
          <h1 className="display-4 mb-4">About Us</h1>
          <p className="lead mb-4">
            At Dave's Laundry Services, we are dedicated to delivering top-notch
            laundry solutions with unmatched quality and care. Our mission is to
            ensure your garments receive the utmost attention and freshness
            while providing convenience and reliability for all your laundry
            needs.
          </p>
          <h4 className="w-100 text-center">David Storch</h4> <br />
          <div className="social-media-links">
            <a
              href="https://www.facebook.com/profile.php?id=61560703765569"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="social-icon" />
            </a>
            <a
              href="https://instagram.com/daveslaundryservices"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="social-icon" />
            </a>
            <a href="mailto:daveslaundryservices@gmail.com">
              <FaEnvelope className="social-icon" />
            </a>
            <a href="tel:+61 490 175 747">
              <FaMobileRetro className="social-icon" />
            </a>
          </div>
        </Col>

        <Col md={6} className="text-center">
          <Image
            src={require("../../assets/images/laundry.jpg")}
            alt="Dave's Laundry Services"
            fluid
            className="rounded shadow-lg"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
