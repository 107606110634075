import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

const LottieAnimation = ({
  animationData,
  loop = true,
  autoplay = true,
  height,
  width,
}) => {
  const [dimensions, setDimensions] = useState({
    height: height,
    width: width,
  });

  // Detect if the user is on a mobile device
  const isMobileDevice = () => {
    return window.innerWidth <= 768;
  };

  useEffect(() => {
    // If on mobile, set dimensions to 200x200
    if (isMobileDevice()) {
      setDimensions({ height: 200, width: 200 });
    }
  }, []);

  const defaultOptions = {
    loop: loop,
    autoplay: autoplay,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      clearCanvas: true,
      progressiveLoad: false,
      hideOnTransparent: true,
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={dimensions.height}
      width={dimensions.width}
      speed={0.4}
    />
  );
};

export default LottieAnimation;
