import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./slider.css"; // Import custom CSS for additional styling

const CarouselComponent = () => {
  return (
    <div className="carousel-container mt-5">
      <Carousel controls={true} indicators={true} interval={3000}>
        <Carousel.Item>
          <div className="carousel-background slide1"></div>
          {/* <Carousel.Caption>
            <h3>First Slide Label</h3>
            <p>Description for the first slide.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-background slide2"></div>
          {/* <Carousel.Caption>
            <h3>Second Slide Label</h3>
            <p>Description for the second slide.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-background slide3"></div>
          {/* <Carousel.Caption>
            <h3>Third Slide Label</h3>
            <p>Description for the third slide.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-background slide4"></div>
          {/* <Carousel.Caption>
            <h3>Third Slide Label</h3>
            <p>Description for the third slide.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-background slide5"></div>
          {/* <Carousel.Caption>
            <h3>Third Slide Label</h3>
            <p>Description for the third slide.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
