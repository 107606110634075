// components/StickyCallButton.js
import React from "react";
import "./style.css"; // Import CSS file for styling
import { FaPhone } from "react-icons/fa"; // Import an icon

const StickyCallButton = () => {
  return (
    <div>
      <a href="tel:+61 490 175 747" className="sticky-call-button">
        <FaPhone />
      </a>
    </div>
  );
};

export default StickyCallButton;
