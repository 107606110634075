import React from "react";
import { Container } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
    <Container className="mt-5 full-height">
      <h1 className="mb-4">Terms and Conditions</h1>

      <h4>1. Care and Liability</h4>
      <p>
        Dave's Laundry Services is committed to providing exceptional care for
        your clothes and linens. However, we cannot be held responsible for any
        damage resulting from the normal laundering process.
      </p>

      <h4>2. Washing Instructions</h4>
      <p>
        Please ensure that all items included in a single load are suitable to
        be washed together. For instance, avoid mixing whites with darks to
        prevent discoloration.
      </p>

      <h4>3. Special Care Items</h4>
      <p>
        If you have items that require special care, please inform us prior to
        your service. We will attempt to address any specific needs, such as
        stain removal, with standard stain removers. However, we cannot
        guarantee the complete removal of all stains.
      </p>

      <h4>4. Drying Instructions</h4>
      <p>
        All items will be tumble-dried unless you specify otherwise. It is your
        responsibility to ensure that all items are suitable for laundering as
        per your instructions. We are not liable for any loss or damage arising
        from the use of our services.
      </p>

      <h4>5. Responsibility</h4>
      <p>
        By using Dave's Laundry Services, you accept full responsibility for the
        condition and suitability of your items for laundering.
      </p>
    </Container>
  );
};

export default TermsAndConditions;
