// src/components/Footer.js
import React from "react";
import { Container } from "react-bootstrap";
import "../Pages/Home/home.css"; // Import the CSS file

const Footer = () => {
  return (
    <footer className="bg-info text-light py-4">
      <Container className="text-center">
        <p>
          © 2024 Dave's Laundry Service. All rights reserved.{" "}
          <a href="/terms">Terms and Conditions</a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
