// src/components/Home.js
import React from "react";
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import "./home.css"; // Import the CSS file
import LottieAnimation from "../../components/LottieAnimation";
import washingMachineAnimation from "../../assets/animation/Animation - 1.json";
import animation from "../../assets/animation/Animation - 2.json";
import household from "../../assets/animation/Animation -3.json";
import bubbles from "../../assets/animation/bubble.json";
import deliveryAnimation from "../../assets/animation/delvery-van.json";
import handshake from "../../assets/animation/handshake.json";
import StickyCallButton from "../../components/stickyCall/StickyCallButton";
import BulletPoints from "../../components/ServicePoints/BulletPoints";
import BlockQuote from "../../components/BlockQuote";

export const ServicesCard = () => {
  const services = [
    {
      id: "001",
      title: "Everyday Clothes",
      description: [
        "Handling regular, everyday clothing with care and precision.",
        "Use of high-quality detergents and fabric softeners.",
        "Ensures your laundry is fresh and soft.",
      ],
      animation: household,
      height: 200,
      width: 200,
    },
    {
      id: "002",
      title: "We Service Business",
      description: [
        "Restaurants",
        "Spas and Salons",
        "Clinics",
        "Gyms and fitness center",
        "Daycare Centers",
        "Rehabilitation Centers",
        "Physio",
        "and more..",
      ],
      animation: handshake,
      height: 200,
      width: 200,
    },
    {
      id: "003",
      title: "Household Linens",
      description: [
        "Washing and caring for household items like linens, tablecloths, doonas, and blankets.",
        "Ensures items are handled with care for the best results.",
      ],
      animation: washingMachineAnimation, // Animation for household linens care
      height: 200,
      width: 200,
    },
    {
      id: "004",
      title: "Pick-Up and Delivery",
      description: [
        "Convenient pick-up and delivery services to save you time.",
        "Collection of laundry from your doorstep.",
        "Returns your items cleaned and folded.",
      ],
      animation: deliveryAnimation, // Animation for pick-up and delivery service
      height: 200,
      width: 300,
    },
  ];

  return (
    <Container className="mt-3 p-1 ">
      <Row>
        {services.map((service, index) => (
          <Col md={3} key={index} className="mb-4">
            <Card className="h-100 custom-dls-card hvr-float-shadow">
              <Card.Body>
                <LottieAnimation
                  animationData={service?.animation}
                  height={service?.height}
                  width={service?.width}
                  loop={true}
                />
                <Card.Title className="titleText">{service.title}</Card.Title>
                <div className="bullet-container">
                  <div className="custom-bullet-list row">
                    {service.description.map((point, index) => (
                      <div className="mt-3 col-12" key={index}>
                        {/* <FaBullseye className="icon position-relative" /> */}
                        <p
                          className={`font-small ${
                            service.id === "002" ? "mb-0" : ""
                          }`}
                        >
                          {point}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const Home = () => {
  return (
    <>
      <StickyCallButton />

      {/* Parallax Section */}
      <div className="parallax">
        <Container className="parallax-content">
          <Row>
            <Col>
              <h1 className="home-title">
                Experience Hassle-Free Laundry Services
              </h1>
              <p className="home-subtitle">
                We handle your laundry with care, so you don’t have to.
              </p>
              <Button
                className="home-button"
                variant="info"
                size="lg"
                onClick={() => {}}
                href="tel:+61 490 175 747"
              >
                Schedule a Pickup
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                className="home-button"
                variant="info"
                size="lg"
                onClick={() => {}}
                href="mailto:daveslaundryservices@gmail.com"
              >
                Leave a Message
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Non-parallax content (e.g., service highlights, testimonials, etc.) */}
      <div className="content-section h-100 container-layout ">
        <Container className="pt-5 text-center service-container">
          <h2 className="titleText">Why Choose Dave's Laundry Services?</h2>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <BulletPoints />
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="lottie-container">
                <LottieAnimation
                  animationData={animation}
                  height={500}
                  width={700}
                  loop={true}
                />{" "}
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* Non-parallax content (e.g., service highlights, testimonials, etc.) */}
      <div className="content-section h-100 quote-section d-none-s">
        <Container className="pt-5 text-center service-container">
          <BlockQuote />
        </Container>
      </div>

      {/* Testimonials Section */}
      <div className="testimonials-section testimonial floating-animation-section">
        <Container className="floating-wrapper">
          <Row>
            <div className="col-md-6 col-sm-12">
              <div className="lottie-container d-none-s">
                <LottieAnimation
                  animationData={bubbles}
                  height={700}
                  width={700}
                  loop={true}
                />{" "}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="lottie-container d-none-s">
                <LottieAnimation
                  animationData={bubbles}
                  height={700}
                  width={700}
                  loop={true}
                />{" "}
              </div>
            </div>
          </Row>
          <div className="floating-modal">
            <h1 className="dark-title">
              Contact us today to streamline your business’s laundry needs with
              our professional and affordable service.
            </h1>
            <p className="home-subtitle">
              Enjoy a clean, organised home without the hassle. Contact us today
              to schedule your first service and experience the ease and quality
              of Dave’s Laundry Service.
            </p>
            <Button
              className="button-87"
              variant="info"
              size="lg"
              onClick={() => {}}
              href="tel:+61 490 175 747"
            >
              Contact Us Now
            </Button>
          </div>
        </Container>
      </div>

      {/* Testimonials Section */}
      <div className="testimonials-section">
        <ServicesCard />
      </div>

      <div
        className="disclaimer-container text-center my-4 p-4"
        style={{
          backgroundColor: "#f8f9fa",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
      >
        <p
          className="disclaimer-text mb-3"
          style={{ fontSize: "16px", color: "#333" }}
        >
          <strong>Disclaimer:</strong> Dave's Laundry Services strives to
          provide accurate information and high-quality services. However, the
          content on this site is for informational purposes only and may not
          reflect the most up-to-date service details. Please contact us
          directly for any specific inquiries or concerns.
        </p>

        <p
          className="ndis-text mb-0"
          style={{ fontSize: "16px", color: "#555" }}
        >
          We are working towards providing our services in partnership with NDIS
          in the near future.
        </p>
      </div>
    </>
  );
};

export default Home;
