import React, { useState } from "react";
import { FaWeightHanging, FaArrowRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { FaMobileRetro } from "react-icons/fa6";

const Calculator = () => {
  const [bags, setBags] = useState(0);
  const [halfBags, setHalfBags] = useState(0);
  const [extraLoads, setExtraLoads] = useState(0);

  // Constants
  const basePrice = 66; // $66 for up to 10kg
  const halfBagPrice = 30; // $30 for additional half-bag
  const extraLoadPrice = 12.5; // Average price for additional load ($10-$15)

  // Calculations
  const calculatePrice = () => {
    let total = 0;

    // Base price for the first 10kg
    total += basePrice * bags;

    // Additional half-bags
    total += halfBags * halfBagPrice;

    // Additional loads
    total += extraLoads * extraLoadPrice;

    return total.toFixed(2);
  };

  return (
    <div className="calculator container p-4 w-100">
      <h2 className="text-center mb-4">Wash, Dry & Fold Package Calculator</h2>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          {/* Wash, Dry, and Fold */}
          <div className="service-section">
            <h4>
              <FaWeightHanging className="icon" /> Wash, Dry & Fold Package
            </h4>
            <p>Our Wash, Dry & Fold service includes the following:</p>
            <ul>
              <li>Free Quote</li>
              <li>Free Pick-Up</li>
              <li>10kg Capacity per Load</li>
              <li>Free Delivery</li>
              <li>24 Hour Turnaround</li>
            </ul>
            <p>
              <strong>Valued at $66 per load.</strong>
            </p>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          {/* Estimate Inputs */}
          <div className="service-section text-align-left">
            <h4>
              <FaArrowRight className="icon" /> Estimate Your Cost
            </h4>
            <form>
              <div className="form-group">
                <label>
                  Number of Bags (10kg each):
                  <input
                    type="number"
                    className="quantity-input"
                    value={bags}
                    onChange={(e) => setBags(Number(e.target.value))}
                    min="0"
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Additional Half-Bags:
                  <input
                    type="number"
                    className="quantity-input"
                    value={halfBags}
                    onChange={(e) => setHalfBags(Number(e.target.value))}
                    min="0"
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Extra Split Loads:
                  <input
                    type="number"
                    className="quantity-input"
                    value={extraLoads}
                    onChange={(e) => setExtraLoads(Number(e.target.value))}
                    min="0"
                  />
                </label>
              </div>
            </form>
          </div>
        </div>
        <div className="text-center">
          <a href="tel:+61 490 175 747">
            <FaMobileRetro className="social-icon" /> <br />
            Call us now!
          </a>
        </div>
      </div>
      {/* Total Estimate */}
      <div className="total-cost text-center mt-4">
        <h3>Total Estimate: ${calculatePrice()}</h3>
        <p>All prices include GST.</p>
      </div>
    </div>
  );
};

export default Calculator;
