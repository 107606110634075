import React from "react";
import { FaCheck } from "react-icons/fa"; // Import an icon
import "./style.css"; // Import the CSS file

const BulletPoints = () => {
  return (
    <div className="bullet-container">
      <div className="custom-bullet-list row">
        <div className="mt-5 col-12">
          <FaCheck className="icon" /> &nbsp; <strong>Trusted partner </strong>
          committed to providing top-notch service and convenience for your
          laundry needs.
        </div>
        <div className="mt-4 col-12">
          <FaCheck className="icon" />
          &nbsp; <strong>Care and precision</strong> in handling everyday
          clothes, special garments, and household linens to ensure they look
          and feel their best.
        </div>
        <div className="mt-4 col-12">
          <FaCheck className="icon" />
          &nbsp; <strong> Efficient processes</strong> Efficient processes and
          experienced professionals delivering exceptional results every time.
        </div>
      </div>
    </div>
  );
};

export default BulletPoints;
