// src/components/Pricing.js
import React from "react";
import { Container, Row } from "react-bootstrap";
import { FaBuilding, FaCreditCard, FaMobileAlt } from "react-icons/fa";

import Calculator from "../../components/Calculator";
import "./style.css";

const Pricing = () => {
  return (
    <>
      <Container className="mt-5">
        <div className="pricing-info container p-4">
          <h2 className="text-center mb-4">Pricing Structure</h2>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Service</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Base Service Fee</td>
                <td>Up to 10kg (1 bag)</td>
                <td>$66</td>
              </tr>
              <tr>
                <td>Extra Half-Bag</td>
                <td>Additional up to 5kg</td>
                <td>$30</td>
              </tr>
              <tr>
                <td>Additional Load</td>
                <td>Splitting into two loads (whites & colors)</td>
                <td>$10-$15</td>
              </tr>
            </tbody>
          </table>

          <div className="additional-info mt-4">
            <h4>Additional Information</h4>
            <ul>
              <li>
                <strong>Minimum Service:</strong> The minimum service charge is
                $66, which covers up to 10kg. If your laundry exceeds this
                amount or is split into multiple loads, additional charges
                apply.
              </li>
              <li>
                <strong>No Ironing:</strong> At this stage, ironing is not
                available. We focus on high-quality washing and folding.
              </li>
              <li>
                <strong>Affordable Pricing:</strong> We strive to keep our
                prices affordable without compromising on the quality of
                service.
              </li>
            </ul>
          </div>

          <div className="info-section mt-4">
            <h4> Pricing Structure</h4>
            <ul>
              <li>
                Base Service Fee: $66 for up to 10kg (1 bag). Additional
                Charges: Extra Half-Bag: $30, Additional Load: $10-$15 (e.g.,
                splitting into whites and colors).
              </li>
            </ul>
          </div>

          <div className="payment-info container p-4 mt-4 ">
            <h2 className="text-center mb-4">How Can I Make Payment?</h2>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="payment-option">
                  <FaBuilding className="payment-icon" />
                  <h4>Direct Bank Transfer</h4>
                  <p>
                    Your local Dave's Laundry Services expert will provide you
                    with an invoice after your laundry has been collected.
                    Payment is to be made by direct bank transfer to the payment
                    details listed on the invoice.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="payment-option">
                  <FaMobileAlt className="payment-icon" />
                  <h4>Tap & Go</h4>
                  <p>
                    Payment can also be made at delivery time using tap & go,
                    with an additional fee of 1.4%.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="payment-option">
                  <FaCreditCard className="payment-icon" />
                  <h4>Invoice Provided</h4>
                  <p>
                    Dave's Laundry Services reserves the right to withhold your
                    laundry until payment is received.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {/* Testimonials Section */}
      <div className="testimonials-section testimonial">
        <Container className="p-4 text-center">
          <h2>Estimate Your Price</h2>
          <Row>
            <Calculator />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Pricing;
